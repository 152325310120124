<template>
  <div>
    <Signup />
  </div>
</template>

<script>
import Signup from "../signup/Singup";
export default {
  name: "TheSignup",
  components: {
    Signup
  }
};
</script>
